import React, { useState, useEffect, useRef } from 'react';
import LazyImage from '../../../LazyImage';
import slogan from '@/assets/images/header/home/slogan.png';
import tv from '@/assets/images/header/home/tv.png';
import redWave from '@/assets/images/header/home/red_wave.png';
import tv2 from '@/assets/images/header/home/tv2.png';
import slogan2 from '@/assets/images/header/home/slogan2.png';
import classNames from 'classnames';
import styles from './index.module.scss';

const WebHomeHeader = () => {
  const [activeContainer, setActiveContainer] = useState(0); // 用于跟踪当前活动的容器
  const [isVisible, setIsVisible] = useState(false);
  const [animate, setAnimate] = useState(true);
  const home_header_bg = () => import('@/assets/images/header/home/home-header-bg.jpg').then((module) => ({ default: module.default }));
  const home_header_bg2 = () => import('@/assets/images/header/home/home-header-bg2.jpg').then((module) => ({ default: module.default }));
  const [imagesLoaded, setImagesLoaded] = useState({
    homeHeaderBg: false,
    homeHeaderBg2: false,
  });
  const intervalRef = useRef(null);

  useEffect(() => {
    if (activeContainer === 1 && !imagesLoaded.homeHeaderBg2) {
      setImagesLoaded((prev) => ({ ...prev, homeHeaderBg2: true }));
    } else if (activeContainer === 0 && !imagesLoaded.homeHeaderBg) {
      setImagesLoaded((prev) => ({ ...prev, homeHeaderBg: true }));
    }
  }, [activeContainer, imagesLoaded]);

  useEffect(() => {
    resetInterval();
    return () => clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 初始加载时触发动画
    setAnimate(true);
  }, []);

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      handleActiveContainer();
    }, 15000);
  };

  const handleActiveContainer = () => {
    resetAnimation();
    setActiveContainer((prevActiveContainer) => {
      const newContainer = prevActiveContainer === 0 ? 1 : 0;
      return newContainer;
    });
    resetInterval(); // 重置定时器
  };

  // 重置动画效果
  const resetAnimation = () => {
    setAnimate(false);
    setTimeout(() => setAnimate(true), 50);
  };

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  // 第一张轮播图
  const banner1 = (
    <header className={classNames(styles['carousel-container'], { [styles.active]: activeContainer === 0 })}>
      {imagesLoaded.homeHeaderBg && <LazyImage importFunc={home_header_bg} alt="" className={styles.homeHeaderBg} />}
      <img src={redWave} alt="" className={classNames(styles.redWave, styles['carousel-image'], { [styles['slide-up']]: animate })} style={{ animationDelay: '0.1s' }} />
      <img src={slogan} alt="" className={classNames(styles.slogan, styles['carousel-image'], { [styles['slide-up']]: animate })} style={{ animationDelay: '0.7s' }} />
      <img src={tv} alt="" className={classNames(styles.tv, styles['carousel-image'], { [styles['slide-up']]: animate })} style={{ animationDelay: '1.2s' }} />
      <div className={classNames(styles.tipsText, styles['carousel-image'], { [styles['slide-up']]: animate })} style={{ animationDelay: '2s' }}>手机等小屏终端不适用</div>
    </header>
  );

  // 第二张轮播图
  const banner2 = (
    <header className={classNames(styles['carousel-container'], { [styles.active]: activeContainer === 1 })}>
      {imagesLoaded.homeHeaderBg2 && <LazyImage importFunc={home_header_bg2} alt="" className={styles.homeHeaderBg} />}
      <img src={redWave} alt="" className={classNames(styles.redWave, styles['carousel-image'], { [styles['slide-up']]: animate })} style={{ animationDelay: '0.1s' }} />
      <img src={tv2} alt="" className={classNames(styles.tv2, styles['carousel-image'], { [styles['slide-up']]: animate })} style={{ animationDelay: '0.7s' }} />
      <img src={slogan2} alt="" className={classNames(styles.slogan2, styles['carousel-image'], { [styles['slide-up']]: animate })} style={{ animationDelay: '1.2s' }} />
    </header>
  );

  // 左箭头
  const arrowLeftBox = (
    <div className={classNames(styles['arrow-box'], { [styles.visible]: isVisible }, styles.left)} onClick={handleActiveContainer}>
      <div className={classNames(styles.arrow, styles.left)} />
    </div>
  );

  // 右箭头
  const arrowRightBox = (
    <div className={classNames(styles['arrow-box'], { [styles.visible]: isVisible }, styles.right)} onClick={handleActiveContainer}>
      <div className={classNames(styles.arrow, styles.right)} />
    </div>
  );

  // 切换栏
  const carouselLndicators = (
    <ol className={styles['carousel-indicators']} onClick={handleActiveContainer}>
      <li className={classNames({ [styles.active]: activeContainer === 0 })}></li>
      <li className={classNames({ [styles.active]: activeContainer === 1 })}></li>
    </ol>
  );

  return (
    <div
      className={styles.carousel}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {banner1}
      {banner2}
      {arrowLeftBox}
      {arrowRightBox}
      {carouselLndicators}
    </div>
  );
};

export default WebHomeHeader;