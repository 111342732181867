import React, { useState } from 'react';
import logo from '@/assets/images/header/home/logo.png';
import drop_down from '@/assets/images/header/home/drop_down.png';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './index.module.scss';

const MobileNavs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = useState(false);
    const path = location.pathname;

    const navs = [
        { title: '首页', path: '/', id: 1 },
        { title: '产品优势', path: '/advantage', id: 2 },
        // { title: '应用下载', path: '/appDownload', id: 3 },
        { title: '关于我们', path: '/aboutUs', id: 4 },
    ];

    const handleClick = () => {
        setActive(!active);
    };

    const handleSwitchNav = (nav) => {
        const { path } = nav;
        navigate(path);
        setActive(false);
    };

    const notActiveStatus = (
        <div className={styles.navs_box}>
            <img src={logo} alt="" className={styles.logo} />
            <img src={drop_down} alt="" className={styles.navs_icon} onClick={handleClick} />
        </div>
    );

    const activeStatus = (
        <div className={classNames(styles.navs_box, styles.active)}>
            <div className={styles.navs_box}>
                <img src={logo} alt="" className={styles.logo} />
                <img src={drop_down} alt="" className={styles.navs_icon} onClick={handleClick} />
            </div>
            <div className={styles.navs_title}>
                {navs.map(nav => {
                    return (
                        <span
                            key={nav.id}
                            className={classNames(styles.nav_item, { [styles.active]: path === nav.path })}
                            onClick={() => handleSwitchNav(nav)}
                        >
                            {nav.title}
                        </span>
                    );
                })}
            </div>
        </div>
    );

    return (
        <>
            {active ? activeStatus : notActiveStatus}
        </>
    );
};

export default MobileNavs;